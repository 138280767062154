#sidebar-links {
    list-style: none;
    border-right: 1px solid black;
    border-image: linear-gradient(to bottom, #002156, white) 1 100%;
    padding-bottom: 5em;
    padding-right: 3em;
}

li.sidebar-link {
    padding: 0.5em;
    font-size: 1.2em;
}

a {
    text-decoration: none;
}

.active-nav-link {
    padding-left: 3px;
    border-left: 3px solid #444;
    border-radius: 3px;
    color: #444;
}