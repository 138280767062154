@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

/*
  A flex container to push the footer to the bottom of the screen even if the page
  contents does not extend that far
*/
.footer-pusher {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.expand {
  flex: 1 0 auto;
}

.hidden {
  display: none;
}

/* Buttons are used instead of non-href <a> tags for keyboard accessiblity */
button {
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
}

button, a {
  color: #0473e1;
}

a {
  cursor: pointer;
}

button::-moz-focus-inner {
  border: 0;
}

@media only screen and (min-width: 900px) {
  #burger-menu, .bm-burger-button {
    display: none;
  }
}

@media only screen and (max-width: 899px) {
  .sidebar {
    display: none;
  }
}

h1, h2, h3, h4, h5, h6, p, a, textarea {
  font-family: 'Roboto', sans-serif;
}

p {
    color: #333;
}

.center {
  text-align: center;
}

.inline-block {
  display: inline-block;
}

.align-right {
  text-align: right;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.active-link {
  font-weight: 900;
}

.cursor-pointer {
  cursor: pointer;
}
