.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 20px 40px 20px;
}

.title {
  margin-top: 0px;
  font-weight: 500;
}

.lang-toggle {
  font-size: 1.15em;
  font-weight: 700;
}

.bm-menu-wrap {
  width: auto !important; /* overwrite inline style */
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  left: 20px;
  top: 20px;
}

#burger-menu {
  position: fixed;
  right: auto !important;
  left: 0px;
}
  
/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
  height: 24px !important;
}

/* General sidebar styles */
.bm-menu {
  background: #fff;
  font-size: 1.15em;
  border-right: 1px solid #b8b7ad;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

.bm-item-list > ul {
  border-right: none !important;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  display: none;
}

