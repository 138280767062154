.main-container {
    max-width: 100vw;
}

.flex-container {
    width: 100%;
    display: flex;
}

.content {
    width: 95%;
    margin: auto;
}

.text-container {
    width: 95%;
    margin: auto;
    max-width: 700px;
}
