.page-select-button {
    border-radius: 3px;
    width: 2em;
    height: 2em;
    margin: 0.5em;
    text-align: center;
}

.page-select-button:hover:not(.page-select-button-active) {
    background-color: #fafafa;
}

.page-select-button-active {
    background-color: #eee;
}
