input, textarea {
    display: block;
    width: 100%;
    border-radius: 3px;
    border: 1px solid #777;
    margin-bottom: 2em;
    padding: 0.5em;
    font-size: 1em;
    box-sizing: border-box;
}

.contact-container {
    width: 90%;
    max-width: 600px;
    margin: auto;
}

.send-btn {
    background: #4a67e8;
    color: white;
    font-size: 1.3em;
    border: none;
    border-radius: 3px;
    padding: 0.2em 1em;
}

.message {
    color: green;
}

#contact-form {
    padding-top: 1em;
}