.github-icon {
    font-size: 10em;
    margin-left: 5%;
    cursor: pointer;
}

.github-icon:hover {
    color: #3369be;
}

.software-container > h2 {
    font-size: 1.3em;
}

.software-page-break {
    margin: 3em 0 1em 0;
}
