.lyrics {
    white-space: pre;
    padding-left: 2em;
}

.lyrics-btn {
    color: #444;
}

.select-song-btn, .lyrics-btn {
    margin: 0.5em 0;
    font-size: 1em;
}

.song-selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.song-selector:hover {
    background-color: #fafafa;
}

.download-button {
    color: #777;
    cursor: pointer;
}

.song-downloaded-checkmark {
    color: #080;
    padding-left: 10px;
}

.song-downloaded-checkmark, .download-button {
    margin-left: 1em;
    font-size: 1.05em;
}
