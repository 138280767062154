.player-controls > button {
    color: #333;
    margin: 1.3em 0em 2em 0em;
}

.player-controls > button:hover {
    color: #000;
}

.player-controls {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 200px;
    align-self: center;
}

.music-player {
    padding-top: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.play-pause-btn {
    font-size: 2em;
}

.prev-skip-btn {
    font-size: 1.6em;
}