.footer {
    padding: 2em 2em 0em 2em;
}

.links-title {
    color: black;
    font-weight: bold;
    margin-bottom: 0.5em;
    margin-top: auto;
    clear: both;
    height: 100px;
    min-height: 100px;
    width:100%;
}
