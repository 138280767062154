.loading-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 10%;
    align-items: center;
    justify-content: center;
}

.loading-icon {
    font-size: 6em;
    font-weight: 100;
    color: purple;
}