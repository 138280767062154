.music-container {
    padding-bottom: 3em;
}

.album-cover {
    width: 300px;
    max-width: 90%;
    display: block;
    margin: auto;
}

/* Center controls and tracks on mobile device/small tablet */
@media only screen and (max-width: 899px) {
    .album-container {
        margin: auto;
    }
}

/* Move controls and tracks a bit to the right on a large screen */
@media only screen and (min-width: 900px) {
    .image-and-controls {
        margin-left: 5em;
    }
    .music-container {
        padding-left: 5em;
    }
}

.album-container {
    width: 90%;
    max-width: 500px;
    margin-top: 1em;
}

.album-btn {
    font-size: 1.2em;
    padding-left: 0;
    padding-right: 0;
    margin-right: 1em;
}
